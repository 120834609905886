(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("sbInternalMsgBus"), require("mobx"), require("sbBffIntegration"), require("sbJsExtends"), require("SBTech"));
	else if(typeof define === 'function' && define.amd)
		define("sbAggregatesDataRetrievalLib", ["sbInternalMsgBus", "mobx", "sbBffIntegration", "sbJsExtends", "SBTech"], factory);
	else if(typeof exports === 'object')
		exports["sbAggregatesDataRetrievalLib"] = factory(require("sbInternalMsgBus"), require("mobx"), require("sbBffIntegration"), require("sbJsExtends"), require("SBTech"));
	else
		root["sbAggregatesDataRetrievalLib"] = factory(root["sbInternalMsgBus"], root["mobx"], root["sbBffIntegration"], root["sbJsExtends"], root["SBTech"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__28__, __WEBPACK_EXTERNAL_MODULE__31__) {
return 